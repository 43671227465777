import { posthog } from 'posthog-js'
import { useEffect } from 'react'

export function TrackingInit() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      posthog.init('phc_B2LgeLlZbRXQrUphMR9tABoR6M9u2MVb1m8Z76PMxIe', {
        api_host: 'https://eu.i.posthog.com',
        secure_cookie: true,
        // Already captured manually
        capture_pageview: false,
      })
    }
  }, [])

  return null
}
