import { RemixBrowser } from '@remix-run/react'
import { startTransition, StrictMode } from 'react'
import { hydrateRoot } from 'react-dom/client'

import { init } from './features/errors/tracking/init.client'
import { TrackingInit } from './features/tracking/components/TrackingInit'

init()

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
      <TrackingInit />
    </StrictMode>
  )
})
