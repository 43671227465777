import { useLocation, useMatches } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { replayIntegration } from '@sentry/remix'
import { useEffect } from 'react'

import { dsn, enabled } from './config'

export function init() {
  Sentry.init({
    enabled,
    dsn,
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,

    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      // See https://github.com/getsentry/sentry-javascript/issues/9728
      replayIntegration(),
    ],

    denyUrls: [/^moz-extension:\/\//, /^chrome:\/\/extensions/],
  })
}
